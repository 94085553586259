import {
  ReferralUtilityNoPIIRequestInput,
  ReferralUtilityRequestProviderInput,
} from '../../api/optum/types';
import {
  ReferralWithoutPII,
  PatientWithoutPII,
  PatientProblemList,
  Provider,
} from '@getvim/internal-vim-os-sdk/types';

const ZIP_LENGTH = 5;

const formatProvider = ({
  data: provider,
  shouldGrabMiddleName,
}: {
  data: Provider;
  shouldGrabMiddleName?: boolean;
}): ReferralUtilityRequestProviderInput => {
  const { demographics, facility, npi } = provider ?? {};

  const { firstName, lastName, middleName } = demographics ?? {};
  const { address, contact_info } = facility ?? {};
  const { faxNumber, mobilePhoneNumber, email } = contact_info ?? {};
  const { address1, address2, city, state, zipCode } = address ?? {};
  const fullAddress = address
    ? {
        addressLine1: address1,
        addressLine2: address2,
        city,
        state,
        zipCode: zipCode ? zipCode.slice(0, Math.max(0, ZIP_LENGTH)) : zipCode,
      }
    : undefined;

  let firstNameResultValue = firstName;
  if (shouldGrabMiddleName && middleName) {
    firstNameResultValue = `${firstName} ${middleName}`;
  }

  return {
    firstName: firstNameResultValue,
    lastName,
    npi,
    address: address?.fullAddress,
    fax: faxNumber,
    phone: mobilePhoneNumber,
    email,
    fullAddress,
  };
};

export const getReferralNoPIIFormatRequestInput = async ({
  deployConfig,
  currentReferral,
  patient,
  problemList,
}: {
  deployConfig: string;
  currentReferral: ReferralWithoutPII;
  patient: PatientWithoutPII;
  problemList: PatientProblemList;
}): Promise<ReferralUtilityNoPIIRequestInput | undefined> => {
  const {
    identifiers,
    basicInformation,
    targetProvider: specialist,
    referringProvider,
    conditions,
  } = currentReferral;

  const { ehrReferralId } = identifiers || {};

  const {
    specialty,
    startDate,
    endDate,

    priority,
    numberOfVisits,
    facilityName,
  } = basicInformation || {};

  const { diagnosis } = conditions || {};

  if (!patient || !referringProvider) {
    return undefined;
  }

  const facilityNameFromReferringProvider = referringProvider?.facility?.name;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { ehrPatientId, insurance, pcp } = patient;

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { groupId, ehrInsurance, memberId, payerId } = insurance ?? {};
  let identifier = memberId ? [{ code: 'Subscriber', value: memberId }] : [];
  if (payerId) {
    identifier = [...identifier, { code: 'payerId', value: payerId }];
  }

  return {
    token: currentReferral.token,
    referralEhrId: ehrReferralId,
    specialty,
    startDate,
    endDate,
    numberOfVisits: numberOfVisits === undefined ? undefined : `${numberOfVisits}`,
    diagnosisCodes: formatDiagnosisCodes(diagnosis),
    activeProblemsList: formatDiagnosisCodes(problemList),
    urgency: priority ? ReferralUtilityPriority[priority] : undefined,
    patient: {
      id: ehrPatientId,
      token: patient.token,
      benefitPlan: {
        planName: ehrInsurance ? ehrInsurance : 'No Insurance on File',
        groupId,
        identifier,
      },
    },
    pcp: pcp ? formatProvider({ data: pcp, shouldGrabMiddleName: true }) : undefined,
    specialist: specialist ? formatProvider({ data: specialist }) : undefined,
    requester: {
      ...formatProvider({ data: referringProvider }),
      clinicLocation: facilityName || facilityNameFromReferringProvider,
    },
    deployConfig,
  };
};

const formatDiagnosisCodes = (diagnosis: PatientProblemList) => {
  return diagnosis?.map(({ code, description }) => {
    return { value: code, description };
  });
};

export enum ReferralUtilityPriority {
  ROUTINE = 'Routine',
  URGENT = 'Urgent',
  STAT = 'STAT',
}
