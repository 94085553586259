import { saveReferralIdMapping } from '@getvim/vim-connect/src/infra/common/api/referral/referral-id-mapping';
import { ReferralExternalIdSource } from '@getvim/platform-types';
import { EnrichedReferral } from '@getvim-os/types';
import { internalApi } from '../../api';
import { createLogger } from '../../utils';

const logger = createLogger('referralSaved');

const sendReferralSavedToOptum = async ({
  referral,
  currentReferralOptumId,
  optumDeployConfiguration,
}: {
  referral: EnrichedReferral;
  currentReferralOptumId: string | undefined;
  optumDeployConfiguration: string;
}) => {
  const { referralId } = referral ?? {};
  const referralEhrId = referralId?.toString();

  if (!referralEhrId || !currentReferralOptumId) {
    logger.warning("referral didn't send to optum, we dont have referralId or optumReferralId", {
      referralId: referralEhrId,
      currentReferralOptumId,
      noPHI: true,
    });
    return;
  }

  try {
    await internalApi.optumNoPIIApi.sendReferralSaveInEhr({
      referralId: currentReferralOptumId,
      referralEhrId,
      deployConfig: optumDeployConfiguration,
    });
  } catch (error) {
    logger.error('sendReferralSaveInEhr request failed', {
      referralId: currentReferralOptumId,
      referralEhrId,
      err: error,
      noPHI: true,
    });
  }

  try {
    await saveReferralIdMapping({
      ehrId: referralEhrId,
      externalId: currentReferralOptumId,
      source: ReferralExternalIdSource.OPTUM,
    });
  } catch (error) {
    logger.error('saveReferralIdMapping request failed', {
      ehrId: referralEhrId,
      externalId: currentReferralOptumId,
      err: error,
      noPHI: true,
    });
  }
};

export { sendReferralSavedToOptum };
