import { PatientProblemList } from '@getvim/internal-vim-os-sdk/types';

export function convertICDCodesToString(
  patientProblemList: PatientProblemList,
): PatientProblemList {
  if (!patientProblemList || patientProblemList.length === 0) {
    return patientProblemList;
  }

  return patientProblemList.map((problem) => {
    return { ...problem, code: `${problem?.code}` };
  }) as PatientProblemList;
}
