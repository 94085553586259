export interface AppConfig {
  supportAllInsurances?: boolean;
  optumDeployConfiguration: string;
  autoPopUpModeOnNewReferral?: boolean; // actually works not only for create new referral, also for edit
  insurancesPlanList?: string[];
  optumNonProdUrl?: boolean;
  autoPopUpMode?: {
    create?: boolean;
    edit?: boolean;
  };
}

export enum Screens {
  None = 'none',
  Loading = 'loading',
  LoadingWriteBack = 'loading-writeback',
  WriteBackError = 'writeback-error',
  OptumSearchProvider = 'optum-search-provider',
}

export enum OutgoingEventType {
  'OPEN_OPTUM_REFERRAL_UTILITY' = 'open-optum-referral-utility',
  'CLOSE_OPTUM_REFERRAL_UTILITY' = 'close-optum-referral-utility',
  'ON_SUBMIT_REFERRAL' = 'on-submit-referral',
  'HIDE_OVERLAY' = 'hide-overlay',
}

export enum OrganizationTag {
  ClinicTest = 'ClinicTest',
}

/** TODO exists in runtime types, but not in vim-os types */
export enum ActionStatus {
  SUCCESS = 'success',
  FAILED = 'failed',
}

/** TODO exists in runtime types, but not in vim-os types */
export enum SelectAction {
  WRITEBACK = 'writeback',
  COPY_TO_CLIPBOARD = 'copy_to_clipboard',
}

export enum AppEnv {
  LOCAL = 'local',
  DEV = 'dev',
  STAGING = 'staging',
  DEMO = 'demo',
  PROD = 'prod',
}

export const APP_ID = 'optum-order-utility';
