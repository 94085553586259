import { useEffect, useRef, useState } from 'react';

export function usePrevious<T>(
  value: T,
  { returnInitialValue }: { returnInitialValue?: boolean } = {},
): T | undefined {
  const ref = useRef<T | undefined>();
  const [isFirstRun, setIsFirstRun] = useState(true);
  useEffect(() => {
    ref.current = value;
  });
  useEffect(() => {
    setIsFirstRun(false);
  }, []);
  return isFirstRun && returnInitialValue ? value : ref.current;
}
