import { useFeatureFlag } from '@getvim/feature-flags-react';
import React, { useEffect, useState } from 'react';
import { createLogger } from '../utils';
import { AppConfig } from '../types';
import { internalApi } from '../api';
import App from './AppV2';
import DeprecatedApp from './App';
import { useVimUserMetadata } from '@getvim/internal-vim-os-sdk/react';

const logger = createLogger('referral-utility:AppWrapper');

const AppWrapper = () => {
  const { organization } = useVimUserMetadata();
  const [shouldUseDedicatedEffects, isLoadingFF] = useFeatureFlag({
    flagName: 'optumReferralUtility.shouldUseDedicatedEffects',
    defaultValue: false,
    flagContext: {
      organizationId_string: organization?.identifiers?.id.toString(),
    },
    waitForContext: true,
  });
  const [appConfig, setAppConfig] = useState<AppConfig>();
  useEffect(() => {
    if (shouldUseDedicatedEffects) {
      internalApi.referralUtilityApi
        .getConfiguration()
        .then((data) => {
          const { configuration } = data;
          setAppConfig(configuration!);
        })
        .catch((error) => {
          logger.error(error);
        });
    }
  }, [shouldUseDedicatedEffects]);
  if (isLoadingFF) {
    return null;
  }
  if (!shouldUseDedicatedEffects) {
    return <DeprecatedApp />;
  }
  if (!appConfig) {
    return null;
  }
  return <App appConfig={appConfig} />;
};
export default AppWrapper;
